import { useEffect, useRef, useState } from 'react';
import Console from './Components/Console';
import ConsoleProvider from './Contexts/ConsoleContext';

function Test (props:any){
  return (
    <div>
      <h1>Hello {props.name}!</h1>
      <h2>You are a {props.children}.</h2>
    </div>
  );
}

function App() {
  
  const mask = useRef(null);
  const [state, setState] = useState(false);

  useEffect(() => {
    const timer = setTimeout(() => {
      setState(true);
    }, 500);
    return () => clearTimeout(timer);
  }, []);

  return (
    // <ConsoleProvider>
    // <div className="app">
    //   <nav>
    //     <img src="/logo.svg" className="logo" alt="gbasilveira logo" />
    //     <ul className="nav-links">
    //       <li className="nav-link">Home</li>
    //       <li className="nav-link">About</li>
    //       <li className="nav-link">Projects</li>
    //       <li className="nav-link">Contact</li>
    //     </ul>
    //   </nav>
    //   <Console />
    // </div>
    // </ConsoleProvider>

    <div className={"app" + (state ? " animate" : "")} style={{
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'center',
      height: '100vh',
      width: '100vw',
    }}>
        <div className={
          "mask" 
        } ref={mask}>&nbsp;</div>
        <img src="/logo.svg" alt="Guilherme Silveira logo" style={{
          width: '80%',
          maxWidth: '400px',
        }} />

        <h1>Building Security in <span style={{color: "hsl(120, 50%, 30%)", fontWeight: "600"}}>Web3</span></h1>
        <a href="mailto:gbasilveira@gmail.com">Meet me now!</a>
    </div>


  );
}

export default App;
